// @mui material components
import React, { useEffect, useState } from "react";
// import { CircularProgress } from "@mui/material";
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import OnlineStatusSnackbar from "layouts/onlineStatusSnackbar/onlineStatusSnackbar";
// import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
// import Typed from "typed.js";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
// import MKInput from "components/MKInput";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg.jpg";
// import TextField from "@mui/material/TextField";
// import { GoogleGenerativeAI } from "@google/generative-ai";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";

function Presentation() {
  // const [question, setQuestion] = useState("");
  // const [answer, setAnswer] = useState("");
  // const [showCircular, setShowCircular] = useState(false);

  // const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEY);
  // const model = genAI.getGenerativeModel({
  //   model: "tunedModels/radia-v31-f26vnrij6zco",
  //   generationConfig: {
  //     temperature: 0,
  //     topP: 0.95,
  //     topK: 64,
  //     maxOutputTokens: 8192,
  //     responseMimeType: "text/plain",
  //   },
  // }); // model : gemini-1.5-flash

  // async function run() {
  //   const prompt = question;
  //   setShowCircular(true);
  //   const result = await model.generateContent(prompt);
  //   const response = await result.response;
  //   try {
  //     if (response) {
  //       setShowCircular(false);
  //     }
  //     const text = response.text();
  //     setAnswer(text);
  //   } catch (error) {
  //     console.log(error);
  //     setShowCircular(false);
  //   }
  // }
  // const typedJsRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    // const phi = "\u03A6";
    document.title = "Fastlane Dynamic - Home";

    // const typedJS = new Typed(typedJsRef.current, {
    //   strings: [`${phi}hysics_`, "computer.science()_", "RadDB_"],
    //   typeSpeed: 70,
    //   backSpeed: 70,
    //   backDelay: 200,
    //   startDelay: 500,
    //   loop: true,
    // });

    // return () => typedJS.destroy();
  }, []);

  // function GradientCircularProgress() {
  //   return (
  //     <>
  //       <svg width={0} height={0}>
  //         <defs>
  //           <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
  //             <stop offset="0%" stopColor="#e01cd5" />
  //             <stop offset="100%" stopColor="#1CB5E0" />
  //           </linearGradient>
  //         </defs>
  //       </svg>
  //       <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} size="1rem" />
  //     </>
  //   );
  // }

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <OnlineStatusSnackbar />
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          label: "free registration",
          color: "info",
        }}
        transparent={!isSticky}
        light={!isSticky}
        sticky
        sx={{
          position: isSticky ? "fixed" : "static",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      />
      <MKBox
        minHeight="75vh" // 75vh
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { socialMediaColors },
          }) =>
            `${linearGradient(
              rgba(socialMediaColors.github.dark, 1),
              rgba(socialMediaColors.github.main, 1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          position: "relative",
        }}
      >
        {/* <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            mx="auto"
            alignItems="center"
          >
            <MKBox
              sx={{ width: "100%" }}
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="center"
              alignItems="center"
            >
              <MKInput
                sx={{ input: { color: "white.main" }, width: { xs: "100%", md: "50%" } }}
                label="Ajukan soalan anda di sini"
                variant="outlined"
                fullWidth
                color="error"
                onChange={(e) => setQuestion(e.target.value)}
                onFocus={() => setAnswer("")}
              />
              <MKButton
                sx={{ ml: { xs: 0, md: 5 }, width: 150, mt: { xs: 3, md: 0 } }}
                variant="gradient"
                color="success"
                onClick={() => run()}
                disabled={question === ""}
              >
                Tanya AI
              </MKButton>
            </MKBox> */}

        {/* <MKTypography
              textAlign="center"
              variant="h1"
              color="white"
              mb={6}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // left: "50px",
              })}
            >
              Selamat Datang ke laman web Radia
            </MKTypography> */}
        {/* </Grid>
        </Container> */}
        {/* {answer !== "" && (
          <MKBox
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <MKInput
              variant="outlined"
              fullWidth
              color="success"
              multiline
              InputProps={{
                readOnly: true,
              }}
              value={answer}
              sx={{ input: { color: "white.main" } }}
            />
          </MKBox>
        )} */}
        {/* <MKBox sx={{ display: "flex", flexDirection: "column" }}>
          <Testimonials sx={{ flex: "1 0 33%" }} />
        </MKBox> */}
      </MKBox>
      <Card
        sx={{
          p: 1,
          mx: { xs: 2, lg: 3 },
          mt: -8, // -8
          mb: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        {/*   <Information />
        <DesignBlocks />
        <Pages /> */}
        {/* <Container sx={{ mt: 1 }}>
          <BuiltByDevelopers />
        </Container> */}
        {/* <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}

        {/* <Download />
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      {/* <MKBox pt={0} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Presentation;
