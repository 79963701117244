import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import { DataGrid } from "@mui/x-data-grid";
// import Profile from "pages/LandingPages/Kelas H/sections/Profile";
import routes from "routes";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
// import Information from "pages/LandingPages/Kelas H/sections/Information";
// import Team from "pages/LandingPages/Kelas H/sections/Team";
// import Featuring from "pages/LandingPages/Kelas H/sections/Featuring";
// import Newsletter from "pages/LandingPages/Kelas H/sections/Newsletter";
// import MKButton from "components/MKButton";
// import { UserContext } from "context/UserContext";
import OnlineStatusSnackbar from "layouts/onlineStatusSnackbar/onlineStatusSnackbar";

// const columns = [
//   {
//     field: "id",
//     headerName: "No",
//     flex: 0.1,
//     headerAlign: "center",
//     align: "center",
//     minWidth: 50,
//   },
//   {
//     field: "nama",
//     headerName: "Nama",
//     flex: 0.8,
//     minWidth: 400,
//   },
//   {
//     field: "jawatan",
//     headerName: "Jawatan",
//     flex: 0.5,
//     headerAlign: "center",
//     align: "center",
//     minWidth: 270,
//   },
//   {
//     field: "gred",
//     headerName: "Gred",
//     flex: 0.2,
//     headerAlign: "center",
//     align: "center",
//     minWidth: 100,
//   },
//   {
//     field: "emel",
//     headerName: "E-mel",
//     flex: 0.4,
//     headerAlign: "center",
//     align: "center",
//     minWidth: 270,
//   },
//   {
//     field: "tel",
//     headerName: "No. telefon",
//     flex: 0.2,
//     headerAlign: "center",
//     align: "center",
//     minWidth: 150,
//   },
// ];

function SoftwareDev({ unit, bgImage }) {
  // const { staff } = useContext(UserContext);

  // const staffOfUnit = staff.filter((e) => e.unit === unit);

  // const seksyen = Array.from(new Set(staffOfUnit.map((e) => e.seksyen)));

  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    document.title = `Fastlane Dynamic - ${unit}`;
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <OnlineStatusSnackbar />
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
        transparent={!isSticky}
        light={!isSticky}
        sticky
        sx={{
          position: isSticky ? "fixed" : "static",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { socialMediaColors },
          }) =>
            `${linearGradient(
              rgba(socialMediaColors.github.dark, 1),
              rgba(socialMediaColors.github.main, 1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Page under development
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We are sorry for the inconvenience
            </MKTypography>
            {/* <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Contact us
            </MKButton> */}
            {/* <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find us on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
              </MKTypography>
            </MKBox> */}
          </Grid>
        </Container>
      </MKBox>
      {/*   <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
         <Team />
        <Featuring />
        <Newsletter /> 
      </Card> */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SoftwareDev;
