// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/logo.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Fastlane Dynamic",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/FastlaneDynamic/",
    },
    {
      icon: <XIcon />,
      link: "https://x.com/FastlaneDynamic",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/AimiFirdhaus",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/FastlaneDynamic",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "https://fastlanedynamic.com" },
        { name: "freebies", href: "https://fastlanedynamic.com" },
        { name: "premium tools", href: "https://fastlanedynamic.com" },
        { name: "blog", href: "https://fastlanedynamic.com" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "illustrations", href: "https://fastlanedynamic.com" },
        { name: "bits & snippets", href: "https://fastlanedynamic.com" },
        { name: "affiliate program", href: "https://fastlanedynamic.com" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "https://fastlanedynamic.com" },
        { name: "knowledge center", href: "https://fastlanedynamic.com" },
        { name: "custom development", href: "https://fastlanedynamic.com" },
        { name: "sponsorships", href: "https://fastlanedynamic.com" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "https://fastlanedynamic.com" },
        { name: "privacy policy", href: "https://fastlanedynamic.com" },
        { name: "Malaysia PDPA 2010", href: "https://fastlanedynamic.com" },
        { name: "licenses (EULA)", href: "https://fastlanedynamic.com" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date} Fastlane Dynamic Resources Consultancy Sdn Bhd
      {/* <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography>
      . */}
    </MKTypography>
  ),
};
