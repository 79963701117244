/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content, color }) {
  const { brand, socials, menus } = content;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid xs={12} md={3} sx={{ ml: "auto", mb: { xs: 3, sm: 3, md: 0 } }}>
            {/* mb = 3 */}
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={0} />
                {/* mb={2} */}
              </Link>
              <MKTypography color={color} variant="h6">
                {brand.name}
              </MKTypography>
              <MKTypography mb={0.5} color={color} variant="h6">
                Resources Consultancy Sdn Bhd
              </MKTypography>
              <Grid container rowSpacing={0} flexDirection="column">
                <Grid>
                  <MKTypography color={color} variant="caption">
                    Reg. no. : 202301010302 (1504223-T)
                  </MKTypography>
                </Grid>
                <Grid>
                  <MKTypography color={color} variant="caption">
                    No. 5-2, Jalan Tun Perak 1, Taman Tun Perak, 56000, Cheras, Kuala Lumpur
                  </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color={color}
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                color={color}
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        color={color}
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography color={color} variant="button" fontWeight="regular">
              &copy; {new Date().getFullYear()} Fastlane Dynamic Resources Consultancy Sdn Bhd
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
// DefaultFooter.propTypes = {
//   content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
// };

export default DefaultFooter;
